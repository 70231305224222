import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const  Sidebar = () => {
    const [nav, setNav] = useState([
        { name: "Dashboard", link: "/dashboard", icon: <Icon.Grid className="me-2" />, },
        {
            name: "Sessions", link: "/sessions?page=1", icon: <Icon.Clock className="me-2" />, submenu: [
                { name: "Active Sessions", link: "/sessions/active", icon: <Icon.HourglassSplit className="me-2" />, },
                { name: "Unpaid Sessions", link: "/sessions/unpaid?page=1", icon: <Icon.Hourglass className="me-2" />, },
            ]
        },
        { name: "Charging Stations", link: "/charging-station", icon: <Icon.EvStation className="me-2" />, },
        //Hide on Live
        // { name: "Station Profile", link: "/station-profile", icon: <Icon.PersonLinesFill className="me-2" />, },

        { name: "Users", link: "/users?page=1", icon: <Icon.Person className="me-2" />, },
        { name: "Co-Partners Management", link: "/co-partners?page=1", icon: <Icon.Diagram2 className="me-2" style={{ fontSize: "20px" }} />, },
        { name: "Payment Gateway", link: "/payment", icon: <Icon.CreditCard className="me-2" />, },
        //Hide on DEV
        { name: "Pricing", link: "/pricing", icon: <Icon.Cash className="me-2" />, },

        { name: "Notification", link: "/notification", icon: <Icon.Bell className="me-2" />, },

        // { name: "Announcement", link: "/announcement", icon: <Icon.Megaphone className="me-2" />, },

        { name: "User Wallet Transactions", link: "/transactions?page=1", icon: <Icon.Wallet className="me-2" />, },
        { name: "Top Up List", link: "/topup?page=1", icon: <Icon.ListCheck className="me-2" />, },
        // { name: "Subscription Plan", link: "/subscription", icon: <Icon.Activity className="me-2" />, },
        {
            name: "Subscription Plans", link: "/subscription", icon: <Icon.Activity className="me-2" />, submenu: [
                { name: "Benefits", link: "/subscription/benefits", icon: <Icon.Award className="me-2" />, },               
            ]
        },
        { name: "CIMB Preferred EV+", link: "/cimb", icon: <Icon.Flower2 className="me-2" />, submenu: [
            { name: "CIMB Code", link: "/cimb/code", icon: <Icon.List className="me-2" />, },               
        ] },  
        
        // 

        { name: "Admin Wallet", link: "/system-wallet", icon: <Icon.Wallet className="me-2" />, },    
        { name: "Voucher Bonus", link: "/bonus", icon: <Icon.Percent className="me-2" />, },    
        { name: "Maintenance Mode", link: "/maintenance", icon: <Icon.Gear className="me-2" />, },    
    ])
 
    const dataVal = useSelector((state) => state.dataValue)
    useEffect(() => { }, [dataVal])
    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">
                {nav.map((data, index) => {
                    return (
                        <li className="list_item" key={index}>
                            <NavLink className="nav-link " to={data.link}>
                                <span className='nav-link-icon'>
                                    {data.icon}
                                </span>
                                {data.name}
                            </NavLink>
                            {data.submenu ?
                                <>
                                    <ul className='nav-content collapse show'>
                                        {data.submenu.map((cntnt, ind) => {
                                            return <li key={ind}>
                                                <NavLink className="nav-link " to={cntnt.link}>
                                                    <span className='nav-link-icon'>
                                                        {cntnt.icon}
                                                    </span>
                                                    {cntnt.name}
                                                </NavLink>
                                            </li>
                                        })}

                                    </ul>
                                </>
                                : null}
                        </li>
                    )
                })}
            </ul>

        </aside>
    )
}

export default Sidebar;